import { observer } from "mobx-react-lite";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import { Box, Button, ButtonProps, Dialog, DialogActions, Divider, IconButton, Stack, SvgIcon, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import * as yup from "yup";
import { FieldArray, Form, Formik } from "formik";
import { useAppServices } from "../../app/services";
import { FormAsyncAutocompleteField, FormSwitch, FormTextField } from "../../../common/form/FormComponents";
import { MdAdd, MdClose } from "react-icons/md";
import { DialogTopBar } from "../../core/dialog/DialogComponents";
import { OperatorView } from "../../auth/AuthenticatedViews";
import { FaAngleDoubleRight } from "react-icons/fa";
import { GalaxyMigrateDeploymentInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { ServerListData } from "../../core/data/ListData";
import { ListGalaxyMigrateLinks } from "gc-web-proto/galaxycompletepb/apipb/gmapipb/galaxymigrate_api_pb";
import { GMLinkInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigratelink_pb";
import { PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import { AppHintID, HintButton } from "../../help/HelpCommon";
import { useGrpcApiStore } from "../../grpc/grpcApiStore";
import { ListGalaxyMigrateDeployments } from "gc-web-proto/galaxycompletepb/apipb/deployment_api_pb";
import { useCurrentProjectID } from "../../project/CurrentProjectState";
import Grid from "@mui/material/Grid2";

// ======================
// CreateGalaxyMigrateLinkButton
// ======================

interface CreateGalaxyMigrateLinkButtonProps {
    label?: string;
    icon?: boolean;
    refetchFn?: () => Promise<any>;
}

export const CreateGalaxyMigrateLinkButton: React.FC<CreateGalaxyMigrateLinkButtonProps & Partial<ButtonProps>> = observer((props) => {
    const newLinkDialogState = useDialogState();
    const button = props.icon ? (
        <Tooltip title={"Create New Connection"}>
            <IconButton onClick={newLinkDialogState.open}>
                <SvgIcon>
                    {" "}
                    <MdAdd />
                </SvgIcon>
            </IconButton>
        </Tooltip>
    ) : (
        <Button onClick={newLinkDialogState.open} {...props}>
            {props.label ? props.label : `Create New Connection`}
        </Button>
    );
    return (
        <OperatorView>
            {newLinkDialogState.isOpen && <CreateGalaxyMigrateLinkDialog dialogState={newLinkDialogState} refetchFn={props.refetchFn} />}
            {button}
        </OperatorView>
    );
});

// ======================
// CreateGalaxyMigrateLinkDialog
// ======================

interface CreateGalaxyMigrateLinkDialogProps {
    dialogState: DialogState;
    refetchFn?: () => Promise<any>;
}

export const CreateGalaxyMigrateLinkDialog: React.FC<CreateGalaxyMigrateLinkDialogProps> = observer((props) => {
    const { dialogState } = props;

    return (
        <Dialog open={dialogState.isOpen} fullScreen={useShouldDialogFullScreen()} maxWidth={"md"} fullWidth onClose={dialogState.close}>
            {dialogState.isOpen && <CreateGalaxyMigrateLinkForm dialogState={dialogState} refetchFn={props.refetchFn} />}
        </Dialog>
    );
});

// ======================
// CreateGalaxyMigrateLinkForm
// ======================

interface CreateGalaxyMigrateLinkFormProps {
    dialogState: DialogState;
    refetchFn?: () => Promise<any>;
}

export const CreateGalaxyMigrateLinkForm: React.FC<CreateGalaxyMigrateLinkFormProps> = observer((props) => {
    const { dialogState } = props;
    const { dialogService, gmDeploymentService } = useAppServices();
    const apis = useGrpcApiStore();
    const projectId = useCurrentProjectID();
    const dataFetcher = async () => {
        const req = new ListGalaxyMigrateDeployments.Request()
            .setProjectId(projectId)
            .setOnlyConnected(true)
            .setExcludeHelperNodes(false)
            .setPageParams(new PagerParams().setPerPage(10000));
        const res = await apis.deploymentService.listGalaxyMigrateDeployments(req, null);
        return res.toObject();
    };

    const gmLinkSchema = yup.object({
        server: yup.object({
            serverName: yup.string(),
            serverId: yup.string().required("Specify host name (connection from)."),
        }),
        client: yup.object({
            clientId: yup.string().required("Specify host name (connection to)."),
            clientName: yup.string(),
        }),
        serverAddress: yup.string().required("Specify connection address."),
        serverPort: yup.number().nullable(),
        description: yup.string().nullable(),
        compression: yup.boolean().nullable(),
        proxy: yup.boolean().nullable(),
    });

    const validationSchema = yup.object({
        gmLinks: yup.array(gmLinkSchema),
    });

    const initialValues = {
        gmLinks: [
            {
                server: {
                    serverId: "",
                    serverName: "",
                },
                client: {
                    clientId: "",
                    clientName: "",
                },
                serverAddress: "",
                serverPort: null as number,
                description: "",
                compression: false,
                proxy: false,
            },
        ],
    };

    const getConnectionAddressHelperText = (hostA: string, hostB: string): string => {
        if (!!hostA && !!hostB) {
            return `${hostA} will connect to ${hostB} via this address.`;
        } else {
            return ``;
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                await gmDeploymentService.batchCreateDeploymentLinks(values.gmLinks);
                await gmDeploymentService.galaxyMigrateLinks.fetchData();
                if (!!props.refetchFn) {
                    await props.refetchFn();
                }
                dialogState.close();
            }}
        >
            {(props) => {
                return (
                    <Box>
                        <Form>
                            <DialogTopBar
                                dialogState={dialogState}
                                title={"Create New Connection"}
                                actions={[<HintButton hintID={AppHintID.CREATE_H2H_LINK_FORM} />]}
                            />
                            <Divider />
                            <FieldArray
                                name={"gmLinks"}
                                render={({ push, remove }) => {
                                    const handleDelete = async (index: number) => {
                                        const confirmed = await dialogService.addConfirmDialog({
                                            message: "Are you sure you want to delete this link?",
                                            autoConfirmationQuestionLine: false,
                                        });
                                        if (confirmed) {
                                            remove(index);
                                        }
                                    };

                                    const handleAdd = () => {
                                        push({
                                            server: {
                                                serverId: "",
                                                serverName: "",
                                            },
                                            client: {
                                                clientId: "",
                                                clientName: "",
                                            },
                                            serverAddress: "",
                                            serverPort: "",
                                            description: "",
                                        });
                                    };
                                    return (
                                        <>
                                            <Box pt={2} pb={2}>
                                                {props.values.gmLinks.map((d, i) => {
                                                    return (
                                                        <Box p={2}>
                                                            <Box>
                                                                {i !== 0 && (
                                                                    <IconButton onClick={() => handleDelete(i)}>
                                                                        <SvgIcon>
                                                                            <MdClose />
                                                                        </SvgIcon>
                                                                    </IconButton>
                                                                )}
                                                            </Box>
                                                            <Grid container justifyContent={"flex-start"}>
                                                                <Grid size={7}>
                                                                    <Grid container alignItems={"flex-start"} justifyContent={"space-between"}>
                                                                        <Grid size={5}>
                                                                            <FormAsyncAutocompleteField
                                                                                label={"From Host"}
                                                                                errorGetter={(v) => v.serverId}
                                                                                name={`gmLinks[${i}].client`}
                                                                                valueGetter={(value: GalaxyMigrateDeploymentInfo.AsObject) => ({
                                                                                    clientId: value.deployment.systemId,
                                                                                    clientName: value.deployment.systemName,
                                                                                })}
                                                                                dataFetcher={dataFetcher}
                                                                                isOptionEqualToValue={(
                                                                                    option: GalaxyMigrateDeploymentInfo.AsObject,
                                                                                    value: GalaxyMigrateDeploymentInfo.AsObject
                                                                                ) => {
                                                                                    return option.deployment.systemId === value.deployment.systemId;
                                                                                }}
                                                                                getOptionLabel={(option: GalaxyMigrateDeploymentInfo.AsObject) =>
                                                                                    option.deployment.systemName
                                                                                }
                                                                                customOptionsFilter={async (
                                                                                    options: GalaxyMigrateDeploymentInfo.AsObject[]
                                                                                ) => {
                                                                                    return options.filter((v) => v.deployment.connected);
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid alignItems={"center"} size={2}>
                                                                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={56}>
                                                                                <SvgIcon>
                                                                                    <FaAngleDoubleRight />
                                                                                </SvgIcon>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid size={5}>
                                                                            <FormAsyncAutocompleteField
                                                                                label={"To Host"}
                                                                                name={`gmLinks[${i}].server`}
                                                                                errorGetter={(v) => v.clientId}
                                                                                valueGetter={(value: GalaxyMigrateDeploymentInfo.AsObject) => ({
                                                                                    serverId: value.deployment.systemId,
                                                                                    serverName: value.deployment.systemName,
                                                                                })}
                                                                                dataFetcher={dataFetcher}
                                                                                isOptionEqualToValue={(
                                                                                    option: GalaxyMigrateDeploymentInfo.AsObject,
                                                                                    value: GalaxyMigrateDeploymentInfo.AsObject
                                                                                ) => option.deployment.systemId === value.deployment.systemId}
                                                                                getOptionLabel={(option: GalaxyMigrateDeploymentInfo.AsObject) =>
                                                                                    option.deployment.systemName
                                                                                }
                                                                                customOptionsFilter={async (
                                                                                    options: GalaxyMigrateDeploymentInfo.AsObject[]
                                                                                ) => {
                                                                                    const currentClientId = props.values.gmLinks[i].client.clientId;
                                                                                    let filteredOptions = options.filter(
                                                                                        (v) => v.deployment.systemId !== currentClientId
                                                                                    );

                                                                                    const req = new ListGalaxyMigrateLinks.Request()
                                                                                        .setProjectId(projectId)
                                                                                        .setOnlyConnected(false)
                                                                                        .setSystemIdFilter(currentClientId)
                                                                                        .setPageParams(new PagerParams().setPerPage(10000));

                                                                                    const res = await apis.gmService.listGalaxyMigrateLinks(req, null);
                                                                                    const galaxyLinks = res.toObject();
                                                                                    if (!!galaxyLinks) {
                                                                                        const data = galaxyLinks.itemsList;
                                                                                        const pairs = data
                                                                                            .map((d) => {
                                                                                                return [d.client.systemId, d.server.systemId];
                                                                                            })
                                                                                            .filter((v) => v[0] === currentClientId || v[1] === currentClientId)
                                                                                            .flat();
                                                                                        filteredOptions = filteredOptions.filter(
                                                                                            (d) => !pairs.includes(d.deployment.systemId)
                                                                                        );
                                                                                    }
                                                                                    return filteredOptions;
                                                                                }}
                                                                                disabled={!props.values.gmLinks[i].client.clientId}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid size={2}>
                                                                    {/*                                                <Box display={'flex'} justifyContent={'center'} height={'100%'}>
                                                <Divider orientation={'vertical'}/>
                                            </Box>*/}
                                                                </Grid>
                                                                <Grid size={3}>
                                                                    <FormTextField
                                                                        label={"Connection Address"}
                                                                        name={`gmLinks[${i}].serverAddress`}
                                                                        helperText={getConnectionAddressHelperText(
                                                                            props.values.gmLinks[i].client.clientName,
                                                                            props.values.gmLinks[i].server.serverName
                                                                        )}
                                                                        disabled={
                                                                            !props.values.gmLinks[i].server.serverId || !props.values.gmLinks[i].client.clientId
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Box pt={2}>
                                                                <Box>
                                                                    <Typography variant={"h6"}>{"Options"}</Typography>
                                                                </Box>
                                                                <Stack direction={"column"} spacing={2}>
                                                                    <Box>
                                                                        <FormSwitch
                                                                            label={"Compression"}
                                                                            name={`gmLinks[${i}].compression`}
                                                                            helperText={
                                                                                "Automatically all traffic in this connection. Due to the increased use of system resources for compression, this option is only recommended to be enabled in extremely limited bandwidth situations."
                                                                            }
                                                                        />
                                                                    </Box>
                                                                    <Box>
                                                                        <FormSwitch
                                                                            label={"Proxy"}
                                                                            name={`gmLinks[${i}].proxy`}
                                                                            helperText={
                                                                                "Honor the system HTTPS Proxy environment variables when making connections."
                                                                            }
                                                                        />
                                                                    </Box>
                                                                </Stack>
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                            </Box>
                                        </>
                                    );
                                }}
                            />
                            <Divider />
                            <DialogActions>
                                <Box p={1} display={"flex"}>
                                    <Box pr={2}>
                                        <Button size={"large"} onClick={dialogState.close}>
                                            Cancel
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button
                                            type={"submit"}
                                            variant={"contained"}
                                            color={"primary"}
                                            disabled={
                                                !props.values.gmLinks[0].server.serverId ||
                                                !props.values.gmLinks[0].client.clientId ||
                                                !props.values.gmLinks[0].serverAddress
                                            }
                                            size={"large"}
                                        >
                                            {`Connect`}
                                        </Button>
                                    </Box>
                                </Box>
                            </DialogActions>
                        </Form>
                    </Box>
                );
            }}
        </Formik>
    );
});
