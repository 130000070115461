import { Alert, AlertTitle, Box, Card, MenuItem, Pagination, Select, Stack, Table, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import * as React from "react";
import { ComponentType } from "react";
import { TableLoadingIndicator } from "./DataTable";
import { RefreshButton } from "../CommonIcons";
import { getPageSelectionList, QueryTableProps } from "./QueryTableCommon";
import { EmptyTableContent } from "./TableCommon";
import { QueryTableFilter } from "./QueryTableFilter";
import { QueryTableSort } from "./QueryTableSort";
import { useGlobalTableSortFilterState } from "./TableFilterState";

interface QueryListTableProps<TData, TError, TComponentProps> extends Omit<QueryTableProps<TData, TError>, "columns"> {
    listComponent: ComponentType<{ data: TData; componentProps?: TComponentProps }>;
    grid?: boolean;
    componentProps?: TComponentProps;
}

export const QueryListTable = <TData, TError, TComponentProps>(p: QueryListTableProps<TData, TError, TComponentProps>) => {
    const { queryKey, grid, refetch, title, error, isLoading, isError, data, pageCount, listComponent, pagination, setPagination, emptyTableConfig } = p;
    const globalSortFilterState = useGlobalTableSortFilterState();
    const refreshButton = (
        <Box pr={2}>
            <RefreshButton onClick={() => refetch()} variant={"outlined"} color={"inherit"} />
        </Box>
    );

    if (isError) {
        return (
            <Card>
                <Box width={"100%"} p={2}>
                    <Alert severity={"error"} action={refreshButton}>
                        <AlertTitle>Error Encountered</AlertTitle>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Box>{(error as unknown as Error).message}</Box>
                        </Box>
                    </Alert>
                </Box>
            </Card>
        );
    }

    const paginationContent =
        (pagination && data?.length) > 0 ? (
            <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"flex-end"} pt={2} pb={2} width={"100%"}>
                <Pagination
                    count={pageCount}
                    page={pagination.pageIndex}
                    onChange={(event, page) => {
                        setPagination({ ...pagination, pageIndex: page });
                    }}
                />
                <Typography variant={"caption"} color={"textSecondary"}>
                    {"Go To Page: "}
                </Typography>
                <Box>
                    <Select
                        value={pagination.pageIndex}
                        variant={"outlined"}
                        MenuProps={{
                            slotProps: {
                                paper: {
                                    sx: {
                                        height: "200px",
                                        "&::-webkit-scrollbar": {
                                            width: "10px",
                                            backgroundColor: "transparent",
                                        },
                                        "&::-webkit-scrollbar-thumb": {
                                            borderRadius: "10px",
                                            border: "2px solid transparent",
                                            backgroundColor: "rgba(255,255,255,.1)",
                                            backgroundClip: `content-box`,
                                        },
                                    },
                                },
                            },
                        }}
                        slotProps={{}}
                        onChange={(event) => {
                            setPagination({ ...pagination, pageIndex: Number(event.target.value) });
                        }}
                        sx={{ maxHeight: "35px", lineHeight: 0, width: "70px" }}
                        renderValue={(value: unknown) => <Typography variant={"caption"}>{value as React.ReactNode}</Typography>}
                    >
                        {getPageSelectionList(pageCount).map((p, i) => {
                            return (
                                <MenuItem key={i} value={p}>
                                    {p}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Box>
            </Stack>
        ) : null;

    return (
        <>
            <Grid container justifyContent={"space-between"}>
                <Grid>
                    <QueryTableFilter queryKey={queryKey} refetchQuery={refetch} />
                </Grid>
                <Grid>
                    <QueryTableSort queryKey={queryKey} refetchQuery={refetch} />
                </Grid>
            </Grid>
            {isLoading && (
                <Table>
                    <TableLoadingIndicator />
                </Table>
            )}
            {data?.length === 0 && !isLoading && (
                <Box pt={2} width={"100%"}>
                    <EmptyTableContent emptyTableConfig={emptyTableConfig} isFilterActive={globalSortFilterState.getIsFilterActive(queryKey)} />
                </Box>
            )}
            {grid ? (
                <Grid container spacing={3}>
                    {data?.map((d, i) => {
                        const ListComponent = listComponent;
                        return <ListComponent key={i} data={d} componentProps={p.componentProps} />;
                    })}
                </Grid>
            ) : (
                <>
                    {data?.map((d, i) => {
                        const ListComponent = listComponent;
                        return <ListComponent key={i} data={d} componentProps={p.componentProps} />;
                    })}
                </>
            )}

            {paginationContent}
        </>
    );
};
