import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CreateProject, GetMyProjectRole, GetProjectDetails, ListProjects } from "gc-web-proto/galaxycompletepb/apipb/project_api_pb";
import { useMutationTrackAndAlertError } from "../core/data/useHooksWithErrorHandling";
import { useCurrentProject, useCurrentProjectState } from "./CurrentProjectState";
import { ProjectQueryKeys } from "../../common/QueryKeys";
import { useGrpcApiStore } from "../grpc/grpcApiStore";
import { useAppServices } from "../app/services";
import { FilterParams, PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import { useGlobalTableSortFilterState } from "../../common/table/TableFilterState";

export enum PROJECT_PRODUCT_TYPE {
    CMC,
    CMO,
}

export const useListMyProjects = (page: number, perPage: number) => {
    const apis = useGrpcApiStore();
    const sortFilterState = useGlobalTableSortFilterState().tableStateMap.get(ProjectQueryKeys.listMyProjects);
    console.log("blaha", sortFilterState);
    const queryFn = async () => {
        const req = new ListProjects.Request().setIsAdmin(false).setPageParams(new PagerParams().setPerPage(perPage).setPage(page));

        if (sortFilterState.sortState) {
            req.setSortParams(sortFilterState.sortFilterConfig.sort.getSortParam(sortFilterState.sortState));
        }

        for (let filter of sortFilterState.filters || []) {
            filter.fieldConfig.addFilterToRequest(req, filter.param);
        }
        req.addFilterParams(
            new ListProjects.Request.Filter().setIsSupporting(new FilterParams.BoolFilter().setValue(false).setOp(FilterParams.BoolFilter.Op.EQUAL))
        );

        const res = await apis.projectService.listProjects(req, null);
        return res.toObject();
    };

    return useQuery({
        queryKey: [
            ProjectQueryKeys.listMyProjects,
            page,
            perPage,
            sortFilterState.sortState?.field.label,
            sortFilterState.sortState?.descending.label,
            sortFilterState.filters.map((f) => `${f.fieldConfig.label}: ${f.param.toObject().op} ${f.param.toObject().value}`),
        ],
        queryFn: queryFn,
        refetchInterval: 30 * 1000,
    });
};

export const useGetProjectShareableLicenseEnabled = () => {
    const currentProject = useCurrentProject();
    return currentProject?.getInfo().getAllowHostLicenseCapacityBorrowing();
};
export const useCreateNewProject = () => {
    const queryClient = useQueryClient();
    const apis = useGrpcApiStore();

    return useMutationTrackAndAlertError(
        {
            mutationKey: ["CreateNewProject"],
            mutationFn: async (args: {
                name: string;
                description: string;
                emergencyEmail: string;
                emergencyPhone: string;
                deleteLock: boolean;
                productSelection: Array<PROJECT_PRODUCT_TYPE>;
            }) => {
                const req = new CreateProject.Request()
                    .setName(args.name)
                    .setEmergencyEmail(args.emergencyEmail)
                    .setEmergencyPhone(args.emergencyPhone)
                    .setDeleteLock(args.deleteLock)
                    .setDescription(args.description)
                    .setProductSelection(
                        new CreateProject.Request.ProductSelection()
                            .setCmo(args.productSelection.includes(PROJECT_PRODUCT_TYPE.CMO))
                            .setCmc(args.productSelection.includes(PROJECT_PRODUCT_TYPE.CMC))
                    );
                return await apis.projectService.createProject(req, null);
            },
            onSuccess: async () => {
                await queryClient.refetchQueries({
                    queryKey: [ProjectQueryKeys.listMyProjects],
                });
            },
        },
        "Creating Project..."
    );
};

export const useGetProjectDetails = (projectId: string) => {
    const apis = useGrpcApiStore();
    const { projectService } = useAppServices();
    const setProjectDetails = useCurrentProjectState((s) => s.setCurrentProject);
    const req = new GetProjectDetails.Request().setProjectId(projectId);

    return useQuery({
        queryKey: [ProjectQueryKeys.getProjectDetails, projectId],
        queryFn: async () => {
            const res = await apis.projectService.getProjectDetails(req, null);
            const details = res.getProjectDetails();
            projectService.currentProject.dataLoaded = true;
            projectService.currentProject.data = details;
            setProjectDetails(details);
            return details;
        },
        refetchInterval: 30 * 1000,
    });
};

export const useGetMyProjectRole = (projectId: string) => {
    const apis = useGrpcApiStore();
    const req = new GetMyProjectRole.Request().setProjectId(projectId);
    const setMyProjectRole = useCurrentProjectState((s) => s.setMyRole);
    return useQuery({
        queryKey: [ProjectQueryKeys.getMyProjectRole],
        queryFn: async () => {
            const res = await apis.projectService.getMyProjectRole(req, null);
            const role = res.getMyRole();
            setMyProjectRole(role);
            return role;
        },
        refetchInterval: 30 * 1000,
    });
};
